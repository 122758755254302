body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}
.ant-layout {
  background: #fff !important;
}
.ant-anchor-ink {
  display: none;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #28a5e7;
}
a:hover {
  color: #71bfff !important;
}
.ant-anchor-link-title {
  color: #28a5e7 !important;
}
.ant-anchor-link {
  padding: 7px 0 !important;
}

.ant-drawer-body .ant-anchor-link {
  line-height: 2;
}

.ant-drawer-body button {
  margin-top: 20px;
}
